import { gql } from '@apollo/client'
import { Container } from '@material-ui/core'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { LazyComponent } from '../../components/lazyComponent/lazyComponent'
import { IMAGE_FRAGMENT } from '../../fragments/image'
import { nonNull } from '../../utils/nonNull'
import { HomepageQuery } from './__generated__'
import {
  BANNER_FRAGMENT,
  BANNER_CTA_FRAGMENT,
  BannerCTA,
  Banner,
  COLUMN_FEATURE_FRAGMENT,
  DISCLAIMER_HOME_FRAGMENT,
  FULL_SCREEN_CTA_FRAGMENT,
  NEWS_FRAGMENT,
  OFFERS_FRAGMENT,
  CTALinksProvider,
} from './fragments'

export type HomeProps = HomepageQuery & {
  //
}

const StyledHomeContainer = styled(Container)`
  margin-bottom: ${props => props.theme.typography.pxToRem(65)};
  padding: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(150)};
  }

  .MuiContainer-root {
    padding-left: ${props => props.theme.typography.pxToRem(10)};
    padding-right: ${props => props.theme.typography.pxToRem(10)};
  }

  > .home-promotion {
    padding: 0;
    margin-top: ${props => props.theme.typography.pxToRem(115)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(190)};
    }

    > .MuiContainer-root {
      padding-left: ${props => props.theme.typography.pxToRem(0)};
      padding-right: ${props => props.theme.typography.pxToRem(0)};
    }

    > .MuiContainer-maxWidthLg {
      padding-top: ${props => props.theme.typography.pxToRem(64)};

      ${props => props.theme.breakpoints.up('sm')} {
        padding-top: ${props => props.theme.typography.pxToRem(108)};
      }
    }
  }
`

const BelowTheFold = dynamic(() => import('./fragments/belowTheFold').then(mod => mod.BelowTheFold))

export const Home: React.FC<PropsWithChildren<HomeProps>> = ({ entry, entries }) => {
  // We know this is always a homepage, but technically GraphQL says this could be any entry, so we must first check
  if (!entry || entry.__typename !== 'homePage_homePage_Entry') {
    return null
  }

  return (
    <StyledHomeContainer maxWidth={false}>      
      {entry.bannerHome && (
        <Banner data={nonNull(entry.bannerHome)} />
      )}
      {entry.bannerCta && entry.bannerCtaTitle && (
        <CTALinksProvider>
          <BannerCTA title={entry.bannerCtaTitle} data={nonNull(entry.bannerCta)} />
        </CTALinksProvider>
      )}
      <LazyComponent>
        <BelowTheFold entry={entry} entries={entries} />
      </LazyComponent>
    </StyledHomeContainer>
  )
}

export const HOMEPAGE_QUERY = gql`
  ${BANNER_FRAGMENT}
  ${BANNER_CTA_FRAGMENT}
  ${OFFERS_FRAGMENT}
  ${FULL_SCREEN_CTA_FRAGMENT}
  ${COLUMN_FEATURE_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${NEWS_FRAGMENT}
  ${DISCLAIMER_HOME_FRAGMENT}
  
  query HomepageQuery($uid: [String]) {
    entry(section: "homePage", uid: $uid) {
      ... on homePage_homePage_Entry {
        offersPromotionsCarouselHeading
        bannerHome {
          ...BannerFragment
        }
        bannerCtaTitle
        bannerCta {
          ...CTABannerFragment
        }
        offersPromotions {
          ...OffersFragment
        }
        fullScreenCta {
          ...FullScreenCtaFragment
        }
        columnFeature {
          ...ColumnFeatureFragment
        }
        disclaimer_entry_field {
          ...DisclaimerHomeFragment
        }
        generalDisclaimers {
          ...DisclaimerHomeFragment
        }
      }
    }
    ...NewsFragment
  }
`

export { StyledHeading } from './styledHeading'
