import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import {
  StyledCardContainer,
  StyledCardIconContainer,
  StyledCardList,
  StyledCardWrapper,
  StyledQuickLink,
  StyledTitleCheveron,
  StyledTitleText,
} from './styledQuickLinksCard'

export type QuickLinksCardProps =  React.ComponentProps<typeof StyledCardContainer> & {
  title: string
  iconCode: IconCodeType
  href?: string | undefined
  target?: string | undefined
  variant?: boolean
}

export const QuickLinksCard: React.FC<PropsWithChildren<QuickLinksCardProps>> = ({ title, iconCode, href, target, variant, children, item=true, xs=12, sm=12, ...props }) => (
  <StyledCardContainer item={item} xs={xs} sm={sm} {...props}>
    <StyledCardWrapper container direction="column" justifyContent="flex-start" alignItems="flex-start">
      <StyledCardIconContainer item>
        <StyledTitleText aria-label={title} href={href} target={target === '_blank' ? '_blank' : '_self'} >
          <CreditUnionIcon alt={`${iconCode} icon`} width={49} height={49} variant={variant} iconCode={iconCode}/>
        </StyledTitleText>
      </StyledCardIconContainer>
      <StyledQuickLink container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
        <StyledTitleText aria-label={title} rel="nooppener noreferrer" href={href} target={target === '_blank' ? '_blank' : '_self'} >{ title }</StyledTitleText>
        <StyledTitleCheveron icon={faChevronRight}/>
      </StyledQuickLink>
    </StyledCardWrapper>
    <Grid container>
      <StyledCardList>
        {children}
      </StyledCardList>
    </Grid>
  </StyledCardContainer>
)