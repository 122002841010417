import Grid from '@material-ui/core/Grid'
import React, { ComponentProps, PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledQuickLinksWrapper = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${props => props.theme.typography.pxToRem(152)};
    grid-row-gap: ${props => props.theme.typography.pxToRem(93)};
  }
`

export type QuickLinksWrapperProps =  Omit<ComponentProps<typeof Grid>, 'direction'> & {
  //
}

export const QuickLinksWrapper: React.FC<PropsWithChildren<QuickLinksWrapperProps>> = ({  container = true, alignContent = 'center', justifyContent = 'flex-start', children, ...props }) => {
  return (
    <StyledQuickLinksWrapper container={container} direction="row" alignContent={alignContent} justifyContent={justifyContent} { ...props }>
      { children }
    </StyledQuickLinksWrapper>
  )
}
