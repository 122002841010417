import React, { PropsWithChildren } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { VideoWrapper, StyledReactPlayer } from './styledHomeBanner'

type HomeVideoBannerProps = {
  videoId: string
  videoType: string
  lightBoxStatus?: boolean
  customClass?: string
}

export const HomeVideoBanner: React.FC<PropsWithChildren<HomeVideoBannerProps>> = ({ videoId })=> {
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <VideoWrapper>
      <StyledReactPlayer
        url={videoId}
        playing={true}
        loop={true}
        controls={false}
        muted={true}
        width={isMobile ? '100%' :'106%'}
        height={isMobile ? '100%' : '106%'}
        autohide={true}
        config={{
          youtube: {
            playerVars: {
              controls: 0,
            },
          },
        }}
      />
    </VideoWrapper>)
}
