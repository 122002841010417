import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Image from 'next/legacy/image'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { irem } from '../../../utils'
import { html } from '../../../utils/html'
import { ColumnFeatureFragment } from './__generated__/columnFeature'

const StyledImageContainer = styled.div`
  max-width: ${irem(400)};
  margin: 0 auto;

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: none;
  }
`

const StyledColumnFeature = styled(Grid)`
  padding-bottom: ${props => props.theme.typography.pxToRem(61)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-bottom: 0;
  }

  > div:last-child {
    padding: ${props => props.theme.typography.pxToRem(20)};

    ${props => props.theme.breakpoints.up('sm')} {
      padding: 0;
    }
  }
`

export type ColumnFeatureProps = {
  data: ColumnFeatureFragment[]
}

export const ColumnFeature: React.FC<PropsWithChildren<ColumnFeatureProps>> = ({ data }) => {
  if (!data[0]) {
    return null
  }

  const { leftColumn, rightColumn } = data[0]

  return (
    <StyledColumnFeature container direction="row" alignItems="center" spacing={8}>
      <Grid item xs={12} md={5}>
        {leftColumn && html(leftColumn)}
      </Grid>
      <Grid item xs={12} md={7}>
        {rightColumn?.[0]?.url && rightColumn[0]?.__typename === 'images_Asset' && (
          <StyledImageContainer>
            <Image height={rightColumn[0].height ?? 583} width={rightColumn[0].width ?? 799} src={rightColumn[0].url} alt={rightColumn[0].alt ?? ''} />
          </StyledImageContainer>
        )}
      </Grid>
    </StyledColumnFeature>
  )
}

export const COLUMN_FEATURE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment ColumnFeatureFragment on columnFeature_BlockType {
    leftColumn
    rightColumn {
      ...ImageFragment
    }
  }
`