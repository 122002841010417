import Image from 'next/image'
import React, { PropsWithChildren } from 'react'
import { HomeVideoBanner } from './homeBannerVideo'
import { ImageContainer, VideoBannerWrapper } from './styledHomeBanner'
import { theme } from '../../../../theme'
import { useMediaQuery } from '@material-ui/core'

type HomeBannerMaskProps = {
  src: string
  mobileSrc: string
  videoId?: string
}



export const HomeBannerMask:React.FC<PropsWithChildren<HomeBannerMaskProps>> = ({
  src,
  mobileSrc,
  children,
  videoId,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  
  return (
    <>
      <div >
        <ImageContainer>
          {!isMobile ? (
            <Image
              src={src}
              objectFit="cover"
              layout="fill"
              alt={''}
            />
          ) : (
            <Image
              src={mobileSrc}
              objectFit="cover"
              layout="fill"
              alt={''}
            />
          )}
        </ImageContainer>
        <VideoBannerWrapper>
          <HomeVideoBanner videoId={videoId || ''} videoType="vimeo" />
        </VideoBannerWrapper>
      </div>
      {children}
    </>
  )
}


