import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import { QuickLinksAccordianCard, QuickLinksAccordianCardProps } from './QuickLinksAccordionCard'
import { QuickLinksCard, QuickLinksCardProps } from './QuickLinksCard'
import { QuickLinksItem, QuickLinksItemProps } from './QuickLinksItem'
import { QuickLinksWrapper, QuickLinksWrapperProps } from './QuickLinksWrapper'

export type QuickLinksProps = (
  | ({ card: true; item?: false } & QuickLinksCardProps)
  | ({ card?: false; item: true } & QuickLinksItemProps)
  | ({ card?: false; item?: false } & QuickLinksWrapperProps)
)

//TO DO: Remove `as type` with TypeScript Magic
export const QuickLinks: React.VFC<QuickLinksProps> = ({ card, item, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  if (item) {
    return <QuickLinksItem { ...props as QuickLinksItemProps} />
  }
  
  if (card) {
    return (!isDesktop) ?
      (<QuickLinksAccordianCard { ...props as QuickLinksAccordianCardProps} />) : 
      (<QuickLinksCard { ...props as QuickLinksCardProps} />)
  }

  return <QuickLinksWrapper { ...props as QuickLinksWrapperProps} />
}